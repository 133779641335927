<template>
  <div style="background-color: rgb(252, 252, 252)">
    <!-- <div v-if="!isFinished && userRole != 'Student'" class="test-header">
      <audio-to-text-recorder @audio-to-text="handleAudioToText($event)" />
    </div> -->

    <div class="main-content" style="background-color: white">
      <div>
        <div class="test-item">
          <p class="div-title">测试详情</p>
          <div class="test-item-details">
            <p>学生姓名：{{ decodedQueryObject.targetCNName }}</p>
            <p>测试类型：{{ testTypeString }}</p>
            <p>测试名称：{{ testItem.name }}</p>
            <p>测试来源：{{ testItem.origin }}</p>
            <p>测试描述：{{ testItem.description || "无" }}</p>
          </div>
        </div>
        <el-divider />
      </div>
      <div v-if="isFinished">
        <div class="test-result">
          <p class="div-title">测试结果</p>
          <div class="test-result-details">
            <p v-if="result.haveEnResult">
              英文计时：{{ testItem.enAnswerCountDown }}
            </p>
            <p
              v-if="result.haveEnResult"
              :class="
                result.enCorrect / result.vocCount >= 0.9 ? 'pass' : 'fail'
              "
            >
              英文答对：{{
                result.enCorrect + "/" + result.vocCount
              }}
              正确率：{{
                Math.round((result.enCorrect / result.vocCount) * 10000) / 100 +
                "%"
              }}
              
            </p>
            <p v-if="result.haveCnResult">中文计时：{{ testItem.countDown }}</p>
            <p
              v-if="result.haveCnResult"
              :class="
                result.cnCorrect / result.vocCount >= 0.9 ? 'pass' : 'fail'
              "
            >
              中文答对：{{
                result.cnCorrect + "/" + result.vocCount
              }}
              正确率：{{
                Math.round((result.cnCorrect / result.vocCount) * 10000) / 100 +
                "%"
              }}
            </p>
            <p>
              单词答对：{{
                result.bothCorrect + "/" + result.vocCount
              }}
              正确率：{{
                Math.round((result.bothCorrect / result.vocCount) * 10000) /
                  100 +
                "%"
              }}
            </p>
            <p style="color: deepskyblue">
              Fortunate cookies: {{ selectFortune(result.cnCorrect / result.vocCount >= 0.9) }}
            </p>
          </div>
        </div>
        <el-divider />
      </div>
      <div class="test-table">
        <el-button
          v-if="isFinished"
          class="mt-4"
          style="width: 100%"
          height="100"
          @click="NirvanaVocTest"
          >Nirvana</el-button
        >
        <el-table
          ref="singleTableRef"
          :data="testItem.studentAnswers"
          highlight-current-row
          style="width: 100%"
          @current-change="handleCurrentChange"
        >
          <el-table-column type="index" width="50" />

          <el-table-column
            v-if="isFinished || testItem.type == 4"
            property="englishText"
            label="英文答案"
          >
            <template #default="scope">
              <div>
                {{ scope.row.englishText }}
              </div>
              <!-- <div
                @click="
                  handleEnglishTextColumnClick($event, scope.row.englishText)
                "
              >
                {{ scope.row.englishText }}
              </div> -->
            </template>
          </el-table-column>

          <el-table-column property="englishStudentAnswer" label="英文">
            <template #default="scope">
              <div class="english-answer-item">
                <el-input
                  v-model="scope.row.englishStudentAnswer"
                  :placeholder="scope.row.hint"
                  :disabled="isFinished"
                ></el-input>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="isFinished && result.haveEnResult"
            property="enAnswerResult"
            sortable
            label="英文正误"
            width="120"
          >
            <template #default="scope">
              <div
                v-if="scope.row.enAnswerResult"
                class="english-answer-item-result-correct"
              >
                <el-icon :size="16" color="deepskyblue" style="align: center"
                  ><CircleCheckFilled
                /></el-icon>
              </div>
              <div v-else class="english-answer-item-result-incorrect">
                <el-icon :size="16" color="red" style="align: center"
                  ><CircleClose
                /></el-icon>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isFinished || testItem.type == 6"
            property="chineseText"
            label="释义"
          >
            <template #default="scope">
              <div class="chinese-answer-item">
                <span
                  v-if="testItem.type == 6"
                  v-html="mapCnTextMeaning(scope.row.chineseText)"
                />
                <span v-else v-html="scope.row.chineseText" />
              </div>
            </template>
          </el-table-column>

          <el-table-column property="chineseStudentAnswer" label="中文">
            <template #default="scope">
              <div class="chinese-answer-item" v-if="isFinished || !cnEditable">
                {{ scope.row.chineseStudentAnswer }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isFinished && result.haveCnResult"
            property="cnAnswerResult"
            sortable
            label="中文正误"
            width="120"
          >
            <template #default="scope">
              <div
                v-if="scope.row.cnAnswerResult"
                class="english-answer-item-result-correct"
              >
                <el-icon
                  :size="16"
                  color="deepskyblue"
                  style="align: center; cursor: pointer"
                  @click="
                    handlecnAnswerResultClick(
                      scope.row,
                      false,
                      testItem.studentId,
                      scope.$index
                    )
                  "
                  ><CircleCheckFilled
                /></el-icon>
              </div>
              <div v-else class="english-answer-item-result-incorrect">
                <el-icon
                  :size="16"
                  color="red"
                  style="align: center; cursor: pointer"
                  @click="
                    handlecnAnswerResultClick(
                      scope.row,
                      true,
                      testItem.studentId,
                      scope.$index
                    )
                  "
                  ><CircleClose
                /></el-icon>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isFinished"
            property="bothResult"
            sortable
            label="单词正误"
            width="120"
          >
            <template #default="scope">
              <div
                v-if="scope.row.bothResult"
                class="english-answer-item-result-correct"
              >
                <el-icon :size="16" color="deepskyblue" style="align: center"
                  ><CircleCheckFilled
                /></el-icon>
              </div>
              <div v-else class="english-answer-item-result-incorrect">
                <el-icon :size="16" color="red" style="align: center"
                  ><CircleClose
                /></el-icon>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="isFinished"
            property="timeConsumed"
            label="耗时"
            width="120"
          >
            <template #default="scope">
              <div v-if="scope.row.timeConsumed">
                <span
                  >{{ scope.row.timeConsumed
                  }}<span v-if="scope.row.meaningCount > 1"
                    >/{{ scope.row.meaningCount }}</span
                  ><span>s</span></span
                >
              </div>
              <div v-else>
                <span>-</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            v-if="isFinished"
            property="listeningCount"
            label="left"
            width="50"
          >
          </el-table-column>

          <el-table-column
            v-if="isFinished"
            sortable
            property="count"
            label="标记数"
            width="100"
          >
          </el-table-column>

          <el-table-column
            v-if="isFinished"
            sortable
            property="vocSetName"
            label="list"
            width="120"
          >
          </el-table-column>
        </el-table>
      </div>

      <create-test-form
        v-model="showCreateTestForm"
        :vocList="nirvanaVocList"
        :studentId="decodedQueryObject.studentId"
        :userId="decodedQueryObject.createdBy"
        :origin="'NV of ' + decodedQueryObject.name"
        @on-test-mode-form-cancel="showCreateTestForm = false"
        @on-test-mode-form-confirm="showCreateTestForm = false"
        @on-tool-config-form-close="showCreateTestForm = false"
      ></create-test-form>

      <div>
        <audio ref="vocaudio"></audio>
      </div>
    </div>
  </div>
</template>

<script>
// import TestHeader from "./TestHeader.vue";
import CreateTestForm from "../CreateTestForm.vue";
import pinyin from "pinyin";
import FortuneCookies from "../../../plugins/FortuneCookies.json";
export default {
  name: "NewTestItemResult",

  components: { CreateTestForm },

  computed: {
    testTypeString() {
      switch (this.testItem.type) {
        case 1:
          return "听写英文";
        case 2:
          return "听写中英";
        case 3:
          return "听英写中";
        case 4:
          return "看英写中";
        case 6:
          return "看中写英";
        default:
          return "";
      }
    },
    mapCnTextMeaning() {
      return (text) => {
        let rawCnMeaningArray = [];
        if (text.includes("释义：")) {
          rawCnMeaningArray = this.retrieveCnMeaning(text);
        } else {
          rawCnMeaningArray.push(text);
        }

        //let cnTextArray = rawCnMeaning.split(/[,，.。、;；:：?？!！ ]/);
        let resultCnArray = [];
        for (let j = 0; j < rawCnMeaningArray.length; j++) {
          let cnTextArray =
            rawCnMeaningArray[j].split(/[,，.。、;；:：?？!！ ]/);
          for (let i = 0; i < cnTextArray.length; i++) {
            if (this.isChinese(cnTextArray[i])) {
              resultCnArray.push(cnTextArray[i]);
            }
          }
        }

        return resultCnArray.join("，");
      };
    },
  },

  data() {
    return {
      testItem: {},
      currentRow: {},
      isFinished: false,
      enEditable: true,
      cnEditable: true,
      submitted: false,
      showCreateTestForm: false,
      result: {
        haveEnResult: false,
        haveCnResult: false,
        enCorrect: 0,
        cnCorrect: 0,
        bothCorrect: 0,
        vocCount: 0,
      },
      nirvanaVocList: [],
      currentOverviewId: null,
      userRole: "",
      navigationType: null,
    };
  },

  beforeMount: function () {
    //navigationType 判断页面是刷新还是跳转，0是跳转，1是刷新
    this.navigationType = window.performance.navigation.type;
    // decode query string into object
    let queryString = window.atob(this.$route.query.encodedString);
    queryString = window.decodeURIComponent(queryString);
    this.decodedQueryObject = JSON.parse(queryString);
  },

  mounted: function () {
    //初始化用户角色
    this.userRole = localStorage.getItem("userRole");
    this.getTestById(
      this.decodedQueryObject.id,
      this.decodedQueryObject.studentId
    );
  },

  methods: {
    getTestById: function (testId, studentId) {
      this.$store
        .dispatch("StudentTest/getTestById", {
          id: testId,
          userId: studentId,
        })
        .then((response) => {
          //测试页面可能是从task任务进的，这时候decodedQueryObject不完整，需要补齐
          //如果query里面有task就说明是task过来的，所以初始化
          if (this.decodedQueryObject.task) {
            this.initializeDecodedQueryObject(response.data);
          }
          this.testItem = response.data;
          //在处理多义词之前，加入每个词测试的耗时（多义词当成一个词）
          this.addTimeConsumed();
          //1和6不需要处理多义词
          if (this.testItem.type != 1 && this.testItem.type != 6) {
            this.processMultimeaningVoc();
          }
          this.addEditableToEachVoc();
          this.initializeTestStatus();
          this.initializeEditable();
          this.processStudentAnswers();
          // 这里需要把decodedQueryObject一些需要的值赋值一下，因为现在test页面可能是有task redirect过来的，不包含decodedQueryObject里面的内容
          //如果是test页面submit过来的，就需要提交一次result，对应相应的task，提交task result
          //navigationType 0说明是跳转，防止result页面刷新的时候重复提交结果
          if (this.decodedQueryObject.submitted && this.navigationType === 0) {
            //提交task result;用this.result 数据
            this.addPlanDetailTaskResult();
          }
        })
        .catch((error) => {
          this.$message(error.toString());
        });
    },

    // Function to randomly select a sentence based on test result
    selectFortune: function (testPass) {
      const cookies = FortuneCookies["FortuneCookies"];
      //const monkies = FortuneCookies["FortuneMonkies"];
      if (testPass) {
        return cookies[Math.floor(Math.random() * cookies.length)];
      } else {
        return "";
      }
    },

    addPlanDetailTaskResult: function () {
      let totalTimeConsumed = 0;
      for (let i = 1; i < this.testItem.studentAnswers.length; i++) {
        totalTimeConsumed =
          totalTimeConsumed + this.testItem.studentAnswers[i].timeConsumed > 0
            ? this.testItem.studentAnswers[i].timeConsumed
            : 0;
      }
      let postData = {
        studentPlanDetailTaskId: this.decodedQueryObject.task.id,
        incorrectCount: this.result.haveCnResult
          ? this.result.vocCount - this.result.cnCorrect
          : this.result.vocCount - this.result.enCorrect,
        checkedCount: this.result.vocCount,
        incorrects: "", //哪些词错误，以及助教写的文本
        generatedResult:
          (this.result.haveCnResult
            ? "中文：-" +
              (this.result.vocCount - this.result.cnCorrect) +
              "/" +
              this.result.vocCount
            : "") +
          (this.result.haveEnResult
            ? "英文：-" +
              (this.result.vocCount - this.result.enCorrect) +
              "/" +
              this.result.vocCount
            : ""), //系统copy生产的检查结果
        timeConsumed: totalTimeConsumed / 60, //:precision="2" // 转成分钟
        evaluation: "",
        passed:
          (this.result.cnCorrect / this.result.vocCount) * 100 >
          this.decodedQueryObject.task.criterion
            ? true
            : false,
      };

      this.$store
        .dispatch("StudentPlan/addOrEditStudentPlanDetailTaskResult", postData)
        .then(() => {
          this.$message({
            type: "success",
            message: "任务结果已提交～",
          });
        });
    },

    initializeDecodedQueryObject: function (testData) {
      let task = this.decodedQueryObject.task;
      let submitted = this.decodedQueryObject.submitted;
      //testItem也就是testData先整体赋值给decodedQueryObject，然后调整
      this.decodedQueryObject = testData;
      this.decodedQueryObject.task = task || null;
      this.decodedQueryObject.submitted = submitted || null;
      this.decodedQueryObject.targetCNName =
        localStorage.getItem("targetCNName") ||
        localStorage.getItem("userCNName");
    },

    addTimeConsumed: function () {
      //现在的testItem里面的studentAnswers还没有处理成多义词
      for (let i = 1; i < this.testItem.studentAnswers.length; i++) {
        const timeDiff =
          new Date(this.testItem.studentAnswers[i].updatedDate).getTime() -
          new Date(this.testItem.studentAnswers[i - 1].updatedDate).getTime();
        // Convert milliseconds to seconds
        const timeDiffInSeconds = Math.floor(timeDiff / 1000);
        this.testItem.studentAnswers[i].timeConsumed = timeDiffInSeconds;
      }
    },

    processMultimeaningVoc: function () {
      for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
        let extraMeaningCount = this.extraMultimeaningCount(
          this.testItem.studentAnswers[i]
        );
        //多义词数量，如果不是多义词，那意思数量是1
        this.testItem.studentAnswers[i].meaningCount = extraMeaningCount + 1;
        if (extraMeaningCount > 0) {
          // 添加一个多义词的index标记
          this.testItem.studentAnswers[i].multiMeaningIndex = 1;
          let multiStudentAnswersArray = [];
          if (
            this.testItem.studentAnswers[i].chineseStudentAnswer &&
            this.testItem.studentAnswers[i].chineseStudentAnswer.length > 0 &&
            this.testItem.studentAnswers[i].chineseStudentAnswer.includes("&")
          ) {
            multiStudentAnswersArray = this.testItem.studentAnswers[
              i
            ].chineseStudentAnswer
              .replace("null", "")
              .replace("undefined", "")
              .split("&");
            this.testItem.studentAnswers[i].chineseStudentAnswer =
              multiStudentAnswersArray[0] || "";
          }
          for (let j = 0; j < extraMeaningCount; j++) {
            this.testItem.studentAnswers.splice(
              i + j,
              0,
              JSON.parse(JSON.stringify(this.testItem.studentAnswers[i + j]))
            );
            this.testItem.studentAnswers[i + 1 + j].multiMeaningIndex += 1;
            this.testItem.studentAnswers[i + 1 + j].chineseStudentAnswer =
              multiStudentAnswersArray[1 + j] || "";
          }
        }
        //新增了几个单词，所以遍历的index要跳过这几个新加的单词
        i += extraMeaningCount;
      }
    },

    extraMultimeaningCount: function (voc) {
      let multiMarker = ["②", "③", "④", "⑤", "⑥", "⑦", "⑧", "⑨"];
      // let lastMultiMeaningNumber = "";
      let extraMeaningCount = 0;
      for (let i = 0; i < multiMarker.length; i++) {
        if (voc.chineseText.includes(multiMarker[i])) {
          // lastMultiMeaningNumber = multiMarker[i];
          extraMeaningCount = i + 1;
        }
      }
      return extraMeaningCount;
    },

    addEditableToEachVoc: function () {
      //这里testItem是大对象，里面的studentAnswers才是数组；所以添加allowEdit没有意义；后来取消这个限制了已经；为了避免意外的bug，这里先不修改代码
      for (let i = 0; i < this.testItem.length; i++) {
        this.testItem[i].allowEdit = true;
      }
    },

    // submit: function () {
    //   //找出多义词，拼接学生写过的所有意思，用&，然后提交一次
    //   for (
    //     let answerLoopIndex = 0;
    //     answerLoopIndex < this.testItem.studentAnswers.length;
    //     answerLoopIndex++
    //   ) {
    //     let extraMeaningCount = this.extraMultimeaningCount(
    //       this.testItem.studentAnswers[answerLoopIndex]
    //     );

    //     if (extraMeaningCount >= 1) {
    //       for (let j = 0; j < extraMeaningCount; j++) {
    //         this.testItem.studentAnswers[answerLoopIndex].chineseStudentAnswer =
    //           this.testItem.studentAnswers[answerLoopIndex]
    //             .chineseStudentAnswer +
    //           "&" +
    //           this.testItem.studentAnswers[answerLoopIndex + j + 1]
    //             .chineseStudentAnswer;
    //       }
    //       //如果到达多义词的最后一个分身，就update一下学生提交的答案
    //       this.postVocStudentTestVocabularyAnswer({
    //         id: this.testItem.studentAnswers[answerLoopIndex].id,
    //         englishStudentAnswer:
    //           this.testItem.studentAnswers[answerLoopIndex]
    //             .englishStudentAnswer,
    //         chineseStudentAnswer:
    //           this.testItem.studentAnswers[answerLoopIndex]
    //             .chineseStudentAnswer,
    //         listeningCount:
    //           this.testItem.studentAnswers[answerLoopIndex].listeningCount,
    //       });

    //       answerLoopIndex += extraMeaningCount;
    //     }
    //   }

    //   //点击submit的时候，先提交一个overview，拿到overview id，然后再处理后续的student test status以及答案的校验
    //   this.addStudentOverview()
    //     .then(() => {
    //       this.$store
    //         .dispatch("StudentTest/updateStudentTestStatus", {
    //           id: this.testItem.id,
    //           studentId: this.testItem.studentId,
    //           name: this.testItem.name,
    //           type: this.testItem.type,
    //           origin: this.testItem.origin,
    //           nirvanaCount: 0,
    //           description: this.testItem.description || "",
    //           status: "finished",
    //           published: true,
    //         })
    //         .then((response) => {
    //           if (response.data != -1)
    //             this.getTestById(
    //               this.decodedQueryObject.id,
    //               this.decodedQueryObject.studentId
    //             );
    //           this.submitted = true;
    //         })
    //         .catch((error) => this.$message(error.toString()));
    //     })
    //     .catch((error) => this.$message(error.toString()));
    // },

    // addStudentOverview: function () {
    //   return new Promise((resolve, reject) => {
    //     let postData = {
    //       studentId: this.testItem.studentId,
    //       vocTestId: this.testItem.id,
    //       learningMode: "学生测试",
    //       checkingMode: this.testItem.type.toString(),
    //       vocSetNames: this.decodedQueryObject.origin,
    //     };

    //     this.$store
    //       .dispatch("StudentOverview/addVocStudentOverview", postData)
    //       .then((response) => {
    //         if (response.data != -1) {
    //           this.currentOverviewId = response.data;
    //           resolve(response);
    //         }
    //       })
    //       .catch((error) => {
    //         console.log(error.toString());
    //         reject();
    //       });
    //   });
    // },

    // upsertVocStudentOverviewDetail: function (
    //   overviewId,
    //   vocabularyId,
    //   isCorrect
    // ) {
    //   let postData = {
    //     vocStudentOverViewId: overviewId,
    //     vocabularyId: vocabularyId,
    //     isCorrect: isCorrect,
    //   };

    //   this.$store
    //     .dispatch("StudentOverview/UpsertVocStudentOverviewDetail", postData)
    //     .then((response) => {
    //       if (response.data) {
    //         //to be continued
    //         console.log("detail added");
    //       }
    //     })
    //     .catch((error) => console.log(error.toString()));
    // },

    // addStudentOverviewDetail: function () {
    //   for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
    //     this.upsertVocStudentOverviewDetail(
    //       this.currentOverviewId,
    //       this.testItem.studentAnswers[i].vocabularyId,
    //       this.testItem.studentAnswers[i].bothResult
    //     );
    //   }
    // },

    markIncorrectVoc: function () {
      for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
        if (!this.testItem.studentAnswers[i].bothResult) {
          //更新一下本地的标记count
          this.testItem.studentAnswers[i].count += 1;
          this.addMark(
            this.testItem.studentId,
            this.testItem.studentAnswers[i]
          );
        }
        if (
          !this.testItem.studentAnswers[i].enAnswerResult &&
          (this.testItem.type == 1 || this.testItem.type == 2)
        ) {
          this.tagVoc(
            this.testItem.studentAnswers[i].vocabularyId,
            this.testItem.studentId
          );
        }
      }
    },

    addMark: function (studentId, vocabulary) {
      this.$store
        .dispatch("Vocabulary/markVocabulary", {
          userId: studentId,
          vocabularyId: vocabulary.vocabularyId,
          count: vocabulary.count,
          marked: true,
          updatedBy: -1,
        })
        .then(() => {})
        .catch((error) => console.log(error.toString()));
    },

    cancelMark: function (studentId, vocabulary) {
      //不太确定这里为什么有这个return的逻辑
      //先取消这个if逻辑，不确定会不会有额外的bug，先观察一下
      //if (!vocabulary.marked || vocabulary.count <= 0) return;

      this.$store
        .dispatch("Vocabulary/markVocabulary", {
          userId: studentId,
          vocabularyId: vocabulary.vocabularyId,
          count: vocabulary.count,
          marked: vocabulary.count == 0 ? false : true,
          updatedBy: -1,
        })
        .then(() => {
          console.log("minus 1 mark count; if count is 0, cancel mark");
        })
        .catch((error) => console.log(error.toString()));
    },

    minusMark: function (studentId, vocabulary) {
      if (!vocabulary.marked || vocabulary.count <= 0) return;
      if (vocabulary.count > 1) {
        this.$store
          .dispatch("Vocabulary/markVocabulary", {
            userId: studentId,
            vocabularyId: vocabulary.vocabularyId,
            count: vocabulary.count - 1,
            marked: true,
            updatedBy: -1,
          })
          .then(() => {
            console.log("minus mark");
          })
          .catch((error) => console.log(error.toString()));
      } else {
        this.$store
          .dispatch("Vocabulary/markVocabulary", {
            userId: studentId,
            vocabularyId: vocabulary.vocabularyId,
            count: vocabulary.count - 1,
            marked: false,
            updatedBy: localStorage.getItem("userId") || studentId,
          })
          .then(() => {})
          .catch((error) => console.log(error.toString()));
      }
    },

    tagVoc: function (vocabularyId, studentId) {
      let postData = {
        vocabularyId: vocabularyId,
        userId: studentId,
        vocTagId: 11,
        deleted: false,
      };
      this.$store
        .dispatch("Vocabulary/tagVocabulary", postData)
        .then(() => {})
        .catch((error) => console.log(error.toString()));
    },

    initializeTestStatus: function () {
      if (this.testItem.status == "finished") {
        this.isFinished = true;
      } else {
        this.isFinished = false;
      }
    },

    initializeEditable: function () {
      switch (this.testItem.type) {
        case 1:
          this.enEditable = true;
          this.cnEditable = false;
          break;
        case 2:
          this.enEditable = true;
          this.cnEditable = true;
          break;
        case 3:
        case 4:
          this.enEditable = false;
          this.cnEditable = true;
          break;
        case 6:
          this.enEditable = true;
          this.cnEditable = false;
          break;
        default:
          break;
      }
    },

    processStudentAnswers: function () {
      for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
        if (
          !this.testItem.studentAnswers[i].englishStudentAnswer &&
          !this.testItem.studentAnswers[i].chineseStudentAnswer
        ) {
          this.testItem.studentAnswers[i].enAnswerResult = false;
          this.testItem.studentAnswers[i].cnAnswerResult = false;
          this.testItem.studentAnswers[i].bothResult = false;
          continue;
        }
        this.processAnswerByTestType(i);
      }
      if ([1, 6].includes(this.testItem.type)) {
        this.generateAnswerResultWithoutMultimeaning();
      } else {
        this.generateAnswerResult();
      }
    },

    processAnswerByTestType: function (answerLoopIndex) {
      switch (this.testItem.type) {
        case 1:
          this.result.haveEnResult = true;
          this.result.haveCnResult = false;
          this.processEnAnswer(answerLoopIndex);
          if (this.testItem.studentAnswers[answerLoopIndex].enAnswerResult)
            this.testItem.studentAnswers[answerLoopIndex].bothResult = true;
          else this.testItem.studentAnswers[answerLoopIndex].bothResult = false;
          break;
        case 2:
          this.result.haveEnResult = true;
          this.result.haveCnResult = true;
          this.processEnAnswer(answerLoopIndex);
          this.processCnAnswer(answerLoopIndex);
          if (
            this.testItem.studentAnswers[answerLoopIndex].enAnswerResult &&
            this.testItem.studentAnswers[answerLoopIndex].cnAnswerResult
          )
            this.testItem.studentAnswers[answerLoopIndex].bothResult = true;
          else {
            this.testItem.studentAnswers[answerLoopIndex].bothResult = false;
          }
          break;
        case 3:
          this.result.haveEnResult = false;
          this.result.haveCnResult = true;
          this.processCnAnswer(answerLoopIndex);
          if (this.testItem.studentAnswers[answerLoopIndex].cnAnswerResult)
            this.testItem.studentAnswers[answerLoopIndex].bothResult = true;
          break;
        case 4:
          this.result.haveEnResult = false;
          this.result.haveCnResult = true;
          this.processCnAnswer(answerLoopIndex);
          if (this.testItem.studentAnswers[answerLoopIndex].cnAnswerResult)
            this.testItem.studentAnswers[answerLoopIndex].bothResult = true;
          break;
        case 6:
          this.result.haveEnResult = true;
          this.result.haveCnResult = false;
          this.processEnAnswer(answerLoopIndex);
          if (this.testItem.studentAnswers[answerLoopIndex].enAnswerResult)
            this.testItem.studentAnswers[answerLoopIndex].bothResult = true;
          break;
      }
    },

    processEnAnswer: function (answerLoopIndex) {
      //如果没答案，则算错
      if (
        !this.testItem.studentAnswers[answerLoopIndex].englishStudentAnswer ||
        this.testItem.studentAnswers[answerLoopIndex].englishStudentAnswer ==
          "NOT Answered"
      ) {
        this.testItem.studentAnswers[answerLoopIndex].enAnswerResult = false;
        return;
      }
      if (
        this.testItem.studentAnswers[answerLoopIndex].englishStudentAnswer &&
        this.testItem.studentAnswers[answerLoopIndex].englishText
          .trim()
          .toLowerCase() ==
          this.testItem.studentAnswers[answerLoopIndex].englishStudentAnswer
            .trim()
            .toLowerCase()
      ) {
        this.testItem.studentAnswers[answerLoopIndex].enAnswerResult = true;
      } else {
        this.testItem.studentAnswers[answerLoopIndex].enAnswerResult = false;
      }
    },

    processCnAnswer: function (answerLoopIndex) {
      this.testItem.studentAnswers[answerLoopIndex].cnAnswerResult = false;
      //如果答案为空，则算错
      if (
        !this.testItem.studentAnswers[answerLoopIndex].chineseStudentAnswer ||
        this.testItem.studentAnswers[answerLoopIndex].chineseStudentAnswer ==
          "NOT Answered"
      ) {
        //this.testItem.studentAnswers[answerLoopIndex].cnAnswerResult = false;
        return;
      }

      //先考虑考虑多义词的情况，如果multiMeaningIndex有值，且大于1，则进入多义词判断，如果和前面写得一样，就判错，直接return，不进去下面的代码块
      if (
        this.testItem.studentAnswers[answerLoopIndex].multiMeaningIndex &&
        this.testItem.studentAnswers[answerLoopIndex].multiMeaningIndex > 1
      ) {
        let multiMeaningIndex =
          this.testItem.studentAnswers[answerLoopIndex].multiMeaningIndex;
        for (
          let i = answerLoopIndex - multiMeaningIndex + 1;
          i < answerLoopIndex;
          i++
        ) {
          if (
            this.testItem.studentAnswers[answerLoopIndex]
              .chineseStudentAnswer ==
            this.testItem.studentAnswers[i].chineseStudentAnswer
          ) {
            this.testItem.studentAnswers[
              answerLoopIndex
            ].cnAnswerResult = false;
            return;
          }
        }
      }
      //整合所有答案组
      //处理需要匹配的答案数组
      // let originalAudioText = audioTextArray.join("");
      // audioTextArray.push(originalAudioText);
      //进入单词卡“释义”中处理中文文本，匹配答案;然后拼接到要匹配的答案中
      let cnOriginalTextArray = this.generateOriginalCnTextArray(
        this.testItem.studentAnswers[answerLoopIndex].chineseText
      );
      this.testItem.studentAnswers[answerLoopIndex].correctedAnswers = [
        ...this.testItem.studentAnswers[answerLoopIndex].correctedAnswers.map(
          (item) => item && item.trim()
        ),
        ...cnOriginalTextArray,
      ];

      //过滤掉null，undefined，和空字符串
      this.testItem.studentAnswers[answerLoopIndex].correctedAnswers =
        this.testItem.studentAnswers[answerLoopIndex].correctedAnswers.filter(
          (item) => item != null && item != ""
        );

      //如果学生答案在未处理的情况下就有corrected answer的匹配，则直接判定为正确（为了修复有标点符号而学生答案被一分为二，而不能识别的bug，比如答案：实施，组织，在corrected answer中有匹配，但是分为
      //两个答案分别是实施，和组织，就没有匹配了
      // if (
      //   this.testItem.studentAnswers[answerLoopIndex].correctedAnswers
      //     .map((item) => item && item.trim())
      //     .includes(
      //       this.testItem.studentAnswers[
      //         answerLoopIndex
      //       ].chineseStudentAnswer.trim()
      //     )
      // ) {
      //   this.testItem.studentAnswers[answerLoopIndex].cnAnswerResult = true;
      //   return;
      // }

      //先找corrected answer中的答案做匹配，如果找到匹配的，则为正确，然后return
      let stuAnswerArr = this.testItem.studentAnswers[
        answerLoopIndex
      ].chineseStudentAnswer
        .trim()
        .split(/[,，.。、;；:：?？!！ ]/);
      //把切分前的答案也push进去，让匹配更精确
      stuAnswerArr.push(
        this.testItem.studentAnswers[
          answerLoopIndex
        ].chineseStudentAnswer.trim()
      );
      for (let i = 0; i < stuAnswerArr.length; i++) {
        if (
          this.testItem.studentAnswers[answerLoopIndex].correctedAnswers
            .map((item) => item && item.trim())
            .includes(stuAnswerArr[i])
        ) {
          this.testItem.studentAnswers[answerLoopIndex].cnAnswerResult = true;
          return;
        }
      }

      //如果在corrected answers中没有找到匹配的，则进入单词卡“释义”中处理中文文本，匹配答案
      //新版Naive多了很多中文，比如 释义，例句，备注，需要在新版格式下，找到正确答案，并匹配
      // let cnOriginalTextArray = this.generateOriginalCnTextArray(
      //   this.testItem.studentAnswers[answerLoopIndex].chineseText
      // );
      // for (let i = 0; i < cnOriginalTextArray.length; i++) {
      //   let tempOriginalText = cnOriginalTextArray[i].trim();

      //   for (let j = 0; j < stuAnswerArr.length; j++) {
      //     let tempStudentCnAnswer = stuAnswerArr[j];
      //     if (tempOriginalText == tempStudentCnAnswer) {
      //       this.testItem.studentAnswers[answerLoopIndex].cnAnswerResult = true;
      //       return;
      //     }
      //   }
      // }

      //如果还是没有找到匹配的答案，那就把答案和学生的回答，都转成拼音，然后匹配
      let pinyinStuCnAnswer = pinyin(
        this.testItem.studentAnswers[answerLoopIndex].chineseStudentAnswer,
        {
          style: pinyin.STYLE_NORMAL,
        }
      ).join("");

      let pinyinAnswersArray = this.testItem.studentAnswers[
        answerLoopIndex
      ].correctedAnswers.map((item) =>
        pinyin(item, { style: pinyin.STYLE_NORMAL }).join("")
      );
      if (
        //pinyinAudioTextArray.some((word) => pinyinAnswersArray.includes(word))
        pinyinAnswersArray.includes(pinyinStuCnAnswer)
      ) {
        this.testItem.studentAnswers[answerLoopIndex].cnAnswerResult = true;
        return;
      }
    },

    handlecnAnswerResultClick: function (row, isRight, studentId, rowIndex) {
      if (localStorage.getItem("userRole") == "Student") return;
      row.cnAnswerResult = isRight;
      if (row.multiMeaningIndex) {
        //multiMeaningIndex有东西，不是undefined，说明是多义词，这里处理多一词批改逻辑
        this.changeMultiMeaningVocCorrectness(
          row,
          isRight,
          studentId,
          rowIndex
        );
      } else {
        //update 本地的result div box里的内容
        isRight ? (this.result.cnCorrect += 1) : (this.result.cnCorrect -= 1);
        //改成只关注中文意思，判断是否标心
        if (!isRight) {
          //本地标记增加一个星
          row.count += 1;
          this.addMark(studentId, row);
        } else {
          //和add mark一样，先减去一个星，保证本地的count数量和服务器一致，然后再执行cancel mark
          row.count -= 1;
          this.cancelMark(studentId, row);
        }
        // if (!isRight) {
        //   //本地标记增加一个星
        //   row.count += 1;
        //   this.addMark(studentId, row);
        // } else {
        //   if (this.testItem.type == 3 || this.testItem.type == 4) {
        //     //和add mark一样，先减去一个星，保证本地的count数量和服务器一致，然后再执行cancel mark
        //     row.count -= 1;
        //     this.cancelMark(studentId, row);
        //   } else {
        //     if (row.enAnswerResult) {
        //       row.bothResult = isRight;
        //       isRight
        //         ? (this.result.bothCorrect += 1)
        //         : (this.result.bothCorrect -= 1);
        //       if (row.bothResult) {
        //         //this.minusMark(studentId, row);
        //         //当把中文改为正确，且英文也写对时，从减去一颗星，改为取消该单词的标记
        //         //和add mark一样，先减去一个星，保证本地的count数量和服务器一致，然后再执行cancel mark
        //         row.count -= 1;
        //         this.cancelMark(studentId, row);
        //       }
        //     }
        //   }
        // }
      }

      // 如果验证为对，加入corrected answer； 否则在这条记录中删除
      if (isRight) {
        this.addTestCorrectedAnswer(
          row.vocabularyId,
          row.chineseStudentAnswer,
          localStorage.getItem("userId")
        );
      } else {
        this.deleteTestCorrectedAnswer(
          row.vocabularyId,
          row.chineseStudentAnswer
        );
      }
    },

    changeMultiMeaningVocCorrectness: function (
      row,
      isRight,
      studentId,
      index
    ) {
      let thisMultiMeaningVocList = [];
      let answers = this.testItem.studentAnswers;
      //设定多义词的第一个意思下标位置
      let firstMultiMeaningVocIndex =
        index - (answers[index].multiMeaningIndex - 1);
      thisMultiMeaningVocList.push(answers[firstMultiMeaningVocIndex]);
      //从第一个多义词下标开始，找到多义词所有意思
      while (
        firstMultiMeaningVocIndex < answers.length &&
        answers[firstMultiMeaningVocIndex].englishText ===
          answers[firstMultiMeaningVocIndex + 1].englishText
      ) {
        thisMultiMeaningVocList.push(answers[firstMultiMeaningVocIndex + 1]);
        firstMultiMeaningVocIndex++;
      }
      let multiMeaningCorrect = true;
      let incorrectCount = 0;
      for (let i = 0; i < thisMultiMeaningVocList.length; i++) {
        if (!thisMultiMeaningVocList[i].cnAnswerResult) {
          multiMeaningCorrect = false;
          incorrectCount = incorrectCount + 1;
        }
      }
      //先判断isRight，然后判断是否多义词正确
      if (isRight) {
        //正确且多义词正确，减少标记
        if (multiMeaningCorrect) {
          //本地标记增加一个星
          row.count -= 1;
          this.cancelMark(studentId, row);
          //中文正确数量 +1
          this.result.cnCorrect += 1;
        }
      } else {
        //改错，incorrectCount是1的时候，说明是全对改了一个错，就要减少一个标记
        if (incorrectCount === 1) {
          //本地标记增加一个星
          row.count += 1;
          this.addMark(studentId, row);
          //中文正确数量 -1
          this.result.cnCorrect -= 1;
        }
      }
    },

    addTestCorrectedAnswer: function (
      vocabularyId,
      correctedAnswer,
      updatedBy
    ) {
      let putData = {
        vocabularyId,
        correctedAnswer,
        updatedBy,
      };
      this.$store
        .dispatch("TestCorrectedAnswer/add", putData)
        .then(() => {})
        .catch((error) => {
          this.$message(error.toString());
        });
    },

    deleteTestCorrectedAnswer: function (vocabularyId, correctedAnswer) {
      let deleteData = {
        vocabularyId,
        correctedAnswer,
      };
      this.$store.dispatch("TestCorrectedAnswer/delete", deleteData);
    },

    //新版Naive多了很多中文，比如 释义，例句，备注，需要在新版格式下，找到正确答案，并匹配
    generateOriginalCnTextArray: function (text) {
      let rawCnMeaningArray = [];
      if (text.includes("释义：")) {
        rawCnMeaningArray = this.retrieveCnMeaning(text);
      } else {
        rawCnMeaningArray.push(text);
      }

      //let cnTextArray = rawCnMeaning.split(/[,，.。、;；:：?？!！ ]/);
      let resultCnArray = [];
      for (let j = 0; j < rawCnMeaningArray.length; j++) {
        let cnTextArray = rawCnMeaningArray[j].split(/[,，.。、;；:：?？!！ ]/);
        for (let i = 0; i < cnTextArray.length; i++) {
          if (this.isChinese(cnTextArray[i])) {
            resultCnArray.push(cnTextArray[i].replace("…", ""));
            if (cnTextArray[i].includes("的"))
              resultCnArray.push(
                cnTextArray[i].replace("…", "").replace("的", "")
              );
            if (cnTextArray[i].includes("地"))
              resultCnArray.push(
                cnTextArray[i].replace("…", "").replace("地", "")
              );
          }
        }
      }

      return resultCnArray;
    },

    //从新版Naive中获取中文释义
    retrieveCnMeaning: function (text) {
      let substr = "释义：";
      let indices = [];
      for (const match of text.matchAll(new RegExp(substr, "g"))) {
        indices.push(match.index + "释义：".length);
      }
      let result = [];
      for (let i = 0; i < indices.length; i++) {
        const start = indices[i];
        const end = text.indexOf("<", start);
        result.push(text.substring(start, end));
      }
      return result;
    },
    //从新版Naive中获取中文释义

    isChinese: function (text) {
      //以前的regex必须完全匹配中文，包含标点就不算中文；换一个初步测试更合适的regex
      //let re = /^[\u4e00-\u9fa5]+$/;
      let re =
        /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g;
      if (re.test(text)) return true;
      return false;
    },

    generateAnswerResultWithoutMultimeaning: function () {
      for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
        if (
          this.result.haveEnResult &&
          this.testItem.studentAnswers[i].enAnswerResult
        )
          this.result.enCorrect += 1;
        if (
          this.result.haveCnResult &&
          this.testItem.studentAnswers[i].cnAnswerResult
        )
          this.result.cnCorrect += 1;
        if (this.testItem.studentAnswers[i].bothResult)
          this.result.bothCorrect += 1;
      }
      this.result.vocCount = this.testItem.studentAnswers.length;
    },

    generateAnswerResult: function () {
      let allExtrameaningCount = 0;

      for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
        let extraMeaningCount = 0;
        extraMeaningCount = this.extraMultimeaningCount(
          this.testItem.studentAnswers[i]
        );
        if (extraMeaningCount <= 0) {
          if (
            this.result.haveEnResult &&
            this.testItem.studentAnswers[i].enAnswerResult
          )
            this.result.enCorrect += 1;
          if (
            this.result.haveCnResult &&
            this.testItem.studentAnswers[i].cnAnswerResult
          )
            this.result.cnCorrect += 1;
          if (this.testItem.studentAnswers[i].bothResult)
            this.result.bothCorrect += 1;
        } else {
          let multiEnIsCorrect = true;
          let multiCnIsCorrect = true;
          let multiBothIsCorrect = true;
          for (let j = 0; j < extraMeaningCount + 1; j++) {
            if (
              !this.result.haveEnResult ||
              !this.testItem.studentAnswers[i + j].enAnswerResult
            ) {
              multiEnIsCorrect = false;
            }
            if (
              !this.result.haveCnResult ||
              !this.testItem.studentAnswers[i + j].cnAnswerResult
            ) {
              multiCnIsCorrect = false;
            }
            if (!this.testItem.studentAnswers[i + j].bothResult) {
              multiBothIsCorrect = false;
            }
          }
          if (multiEnIsCorrect) this.result.enCorrect += 1;
          if (multiCnIsCorrect) this.result.cnCorrect += 1;
          if (multiBothIsCorrect) this.result.bothCorrect += 1;
          //多义词总量更新
          allExtrameaningCount += extraMeaningCount;
          i += extraMeaningCount;
        }
      }
      //set total number of voc
      this.result.vocCount =
        this.testItem.studentAnswers.length - allExtrameaningCount;
    },

    handleCurrentChange: function (val) {
      this.currentRow = val;
    },

    handleEnglishTextColumnClick: function (event, englishText) {
      this.$playAudio(englishText, this.$refs.vocaudio);
    },

    handleInputAnswerFocus: function (row, playAudio) {
      //console.log(row);
      //play english word
      if (playAudio && row.listeningCount > -3) {
        //this.playCurrentVoc(row.englishText);
        this.$playAudio(row.englishText, this.$refs.vocaudio);
        row.listeningCount -= 1;
      }
    },

    handleNewInputAnswerFocus: function (scope, playAudio) {
      console.log(scope.$index);
      if (playAudio && scope.row.listeningCount > -3) {
        //即将执行播放，listeningCount - 1
        scope.row.listeningCount -= 1;
        let componentRef = document.getElementById("Audio" + scope.$index);
        componentRef.play().catch(() => {
          console.log("Error catched and switch to Youdao AC interface");
          componentRef.src =
            "http://dict.youdao.com/dictvoice?type=0&audio=" +
            scope.row.englishText;
          componentRef.play().catch(() => {
            console.log("Error catched and switch to Youdao EC interface");
            componentRef.src =
              "http://dict.youdao.com/dictvoice?type=1&audio=" +
              scope.row.englishText;
            componentRef.play().catch(() => {
              console.log(
                "Error catched and switch to dict.cn female interface"
              );
              componentRef.src =
                "http://audio.dict.cn/mbTd30L5d074d3286a200f1f38fe91bbb1aef8ec.mp3?t=" +
                scope.row.englishText;
              componentRef.play().catch(() => {
                console.log(
                  "Error catched and switch to dict.cn male interface"
                );
                componentRef.src =
                  "http://audio.dict.cn/muc0L5459674275cd6bee904e3ed82b5b64a29.mp3?t=" +
                  scope.row.englishText;
                componentRef.play().catch(() => {
                  //如果最后都没播放出来，listeningCount回收1
                  scope.row.listeningCount += 1;
                });
              });
            });
          });
        });
      }
    },

    handleInputAnswerBlur: function (row) {
      this.postVocStudentTestVocabularyAnswer({
        id: row.id,
        englishStudentAnswer: row.englishStudentAnswer,
        chineseStudentAnswer: row.chineseStudentAnswer,
        listeningCount: row.listeningCount,
      });
    },

    handleInputAnswerKeydown: function (key, row) {
      //取消key的监视，这里中文输入法keycode大于193，但不是准确数值，如果之后有bug，需要监控key来观察原因
      //console.log(key);
      if (key.keyCode > 193) {
        row.allowEdit = false;
        setTimeout(() => {
          row.allowEdit = true;
        }, 10);
      }
    },

    postVocStudentTestVocabularyAnswer: function (postData) {
      this.$store
        .dispatch("StudentTest/editVocStudentTestVocabularyAnswer", postData)
        .then()
        .catch(() => {
          this.$message("词汇未提交，请检查网络是否稳定");
        });
    },

    randomArray: function (arrayList) {
      if (arrayList.length === 0 || arrayList.length === null) return;
      var arrayListLength = arrayList.length;
      for (var i = 0; i < arrayListLength; i++) {
        var randomIndex = Math.floor(Math.random() * arrayList.length);
        var tempItem = arrayList[randomIndex];
        arrayList[randomIndex] = arrayList[0];
        arrayList[0] = tempItem;
      }
      return arrayList;
    },

    NirvanaVocTest: function () {
      this.showCreateTestForm = true;
      this.nirvanaVocList = this.testItem.studentAnswers.filter(
        (item) => item.bothResult != true
      );
      this.nirvanaVocList = this.randomArray(this.nirvanaVocList);
    },

    //引入audioToText组件，把语音转成文字数据，然后在改事件中接收
    handleAudioToText: function (data) {
      console.log(data.result.text);
      console.log(this.currentRow);
      this.currentRow.chineseStudentAnswer = data.result.text;
      //因为没有选中过中文inputbox，所以需要在填完中文之后，调用一个post答案的接口
      this.handleInputAnswerBlur(this.currentRow);
      //把拿到的文本写入当前正在激活的测试词汇
    },

    // 使用main.js中定义的全局函数来代替
    // playCurrentVoc: function (englishWord) {
    //   // hole 发音不对，替换成whole
    //   if (englishWord == "hole") englishWord = "whole";
    //   var vocAPI = urls.YoudaoPronunciationUrl;
    //   this.$refs.vocaudio.src = vocAPI + englishWord;
    //   this.$refs.vocaudio.play();
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bg-image {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
}
.main-content {
  margin-left: 2%;
  margin-right: 2%;
}

.english-answer-item-result-correct {
  display: flex;
  justify-content: center;
}

.english-answer-item-result-incorrect {
  display: flex;
  justify-content: center;
}

.test-result,
.test-item {
  // margin-top: 60px;
  border: 1px solid rgb(223, 223, 223);
  border-radius: 1em;

  background-image: url("../../../assets/logo.jpg");
  background-size: 20%;

  background-position: 50% 50%;
  background-repeat: no-repeat;
}

p {
  font-family: "FangSong", "Times New Roman", Times, serif;
}

.test-result-details,
.test-item-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}

.div-title {
  font-weight: bold;
  font-size: 24px;
  color: deepskyblue;
}

.pass {
  color: deepskyblue;
}

.fail {
  color: red;
}

// .test-header {
//   position: fixed;
//   left: 20%;
//   top: 0;
//   width: 60%;
//   height: 50px;
//   text-align: center;
//   z-index: 9999;
// }
</style>
